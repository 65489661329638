// src/components/Header.js
import React from 'react';
import './Header.css';
import Logo from '../assets/logo3.png';
import MPR from '../assets/mpr.jpg';
import CEE from '../assets/CEE.png';
import RGE from '../assets/rge.png';
import EDF from '../assets/edf.png';
import QP from '../assets/qualipac.png';

const Header = () => {
    const handleImageClick = () => {
        window.location.reload();
      };
    return (
        <header className="header">
            <img src={Logo} alt="Logo" className="logo" onClick={handleImageClick}/>
            <div className="header-images">
            <img src={MPR} alt="Logo" className="logo" />
            <img src={CEE} alt="Logo" className="logo" />
            <img src={RGE} alt="Logo" className="logo" />
            <img src={EDF} alt="Logo" className="logo" />
            <img src={QP} alt="Logo" className="logo" />
            </div>
        </header>
    );
};

export default Header;
