// src/App.js
import React from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Questionnaire from './components/Questionnaire';
import "./styles/global.css";
import { Helmet } from 'react-helmet';


const App = () => {
    return (
        <div className="App">
          <Helmet>
            <meta name="theme-color" content="#ffffff" />
          </Helmet>
          <Header />
          <div className="main-content">
            <Sidebar />
            <Questionnaire />
          </div>
        </div>
    );
};

export default App;
