import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';

const RevenueForm = ({
    question,
    options,
    subOptions,
    handleOptionSelectStay,
    handleOptionSelect,
    handleNextButtonClick,
    initialSelectedOptionIndexes
}) => {
    
    const initializeSelectedOptionIndex = () => {
        return initialSelectedOptionIndexes && initialSelectedOptionIndexes.length > 0 
            ? initialSelectedOptionIndexes[1] 
            : null;
    };

    const initializeSelectedSubOptionIndex = () => {
        return initialSelectedOptionIndexes && initialSelectedOptionIndexes.length > 1 
            ? initialSelectedOptionIndexes[0] 
            : null;
    };

    console.log(initialSelectedOptionIndexes[0] )
    console.log(initialSelectedOptionIndexes[1] )
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(initializeSelectedOptionIndex);
    const [selectedSubOptionIndex, setSelectedSubOptionIndex] = useState(initializeSelectedSubOptionIndex);

    
    const handleOptionClick = (index) => {
        
        setSelectedOptionIndex(index);
        handleOptionSelectStay(index);
        setSelectedSubOptionIndex(null); // Réinitialiser la sélection de la sous-option
    };

    const handleSubOptionClick = (index) => {
        console.log("test")
        setSelectedSubOptionIndex(index);
        handleOptionSelect(index);
    };

    return (
        <div className="revenue-form">
            <h2>{question}</h2>
            <div className="option-buttons">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className={`option-button ${selectedOptionIndex === index ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(index)}
                    >
                        {option.title}
                    </button>
                ))}
            </div>
            {selectedOptionIndex !== null && (

                    <div className="sub-options">
                        <p>Choisissez une fourchette de revenu :</p>
                        <div className="sub-options-container">
                        {subOptions[selectedOptionIndex].map((subOption, index) => (
                                <button
                                    key={index}
                                    className={`sub-option-button ${selectedSubOptionIndex === index ? 'selected' : ''}`}
                                    onClick={() => handleSubOptionClick(index)}
                                >
                                    {subOption}
                                </button>
                            ))}
                        </div>
                    </div>

            )}
        </div>
    );
};

export default RevenueForm;
