import React from 'react';

const AddressForm = ({ addressDetails, handleAddressChange }) => {
    return (
        <div className="form-general">
            <h2>Adresse</h2>
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Adresse"
                    value={addressDetails.address}
                    onChange={(e) => handleAddressChange(e, 'address')}
                    required
                />
                <label className={'input-label'}>Adresse</label>
            </div>
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Ville"
                    value={addressDetails.city}
                    onChange={(e) => handleAddressChange(e, 'city')}
                    required
                />
                <label className={'input-label'}>Ville</label>
            </div>
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Code postal"
                    value={addressDetails.postalCode}
                    onChange={(e) => handleAddressChange(e, 'postalCode')}
                    required
                />
                <label className={'input-label'}>Code postal</label>
            </div>
        </div>
    );
};

export default AddressForm;
