import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Questionnaire.css';
import Question from './Question';
import RevenueForm from './RevenueForm';
import ContactForm from './ContactForm';
import AddressForm from './AddressForm';
import LoadingScreen from './LoadingScreen';


// Import des images depuis le dossier src/assets
import Maison from '../assets/house4.png';
import Appartement from '../assets/appart2.png';

import pac from '../assets/pac11.png';
import bois from '../assets/bois5.png';
import gaz from '../assets/gaz4.png';
import elec from '../assets/elec6.png';
import charbon from '../assets/charbon3.png';
import fioul from '../assets/fioul6.png';

import global from '../assets/global.png';
import iso from '../assets/iso.png';
import heat from '../assets/heat.png';
import solar from '../assets/solar.png';
import borne from '../assets/borne.png';
import informations from '../assets/informations.png';

import planete from '../assets/planete.png';

import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'; // Import des icônes

const revenueOptions = [
    [
        '0 € à 23541 €',
        '23542 € à 28657 €',
        '28658 € à 40018 €',
        'Supérieur à 40019€'
    ],
    [
        '0 € à 34551 €',
        '34552 € à 42058 €',
        '42059 € à 58827 €',
        'Supérieur à 58828€'
    ],
    [
        '0 € à 41493 €',
        '41494 € à 50513 €',
        '50514 € à 70382 €',
        'Supérieur à 70383€'
    ],
    [
        '0 € à 48447 €',
        '48448 € à 58981 €',
        '58982 € à 82839 €',
        'Supérieur à 82840€'
    ],
    [
        '0 € à 55427 €',
        '55428 € à 67473 €',
        '67474 € à 94844 €',
        'Supérieur à 94845€'
    ],
    [
        '0 € à 62397 €',
        '62398 € à 75959 €',
        '75960 € à 106850 €',
        'Supérieur à 106851€'
    ],
];

const questions = [
    {
        question: 'Bonjour quel est votre projet ?',
        options: [

            { image: iso, title: 'Isolation Exterieur' },
            { image: heat, title: 'Système de Chauffage' },
            // { image: solar, title: 'Panneaux solaires' },
            // { image: borne, title: 'Borne voiture' },
            // { image: informations, title: 'Je me renseigne' },
        ],
    },
    {
        question: 'Votre projet concerne',
        options: [
            { image: Maison, title: 'Une maison' },
            { image: Appartement, title: 'Un appartement' },
        ],
    },
    {
        question: 'Vous êtes',
        options: [
            { title: 'Propriétaire occupant' },
            { title: 'Propriétaire non occupant' },
            { title: 'Locataire' },
        ],
    },
    {
        question: 'Quelle est la date de construction du logement',
        options: [
            { title: 'Moins de 2 ans' },
            { title: 'Entre 2 et 15 ans' },
            { title: 'Plus de 15 ans' },
        ],
    },
    {
        question: 'Mode de chauffage actuel',
        options: [
            { image: pac, title: 'Pompe à chaleur' },
            { image: elec, title: 'Électrique' },
            { image: fioul, title: 'Fioul' },
            { image: gaz, title: 'Gaz' },
            { image: bois, title: 'Bois' },
            { image: charbon, title: 'Charbon' },
        ],
    },
    {
        question: 'Quelle est la surface du logement (en m²) ?',
        options: [
            { type: 'number', placeholder: 'Surface en m²', value: '' },
        ],
    },
    {
        question: 'Quelle est l’adresse du logement pour votre projet ?',
        options: [
            { type: 'text', placeholder: 'Adresse', value: '' },
            { type: 'text', placeholder: 'Ville', value: '' },
            { type: 'text', placeholder: 'Code postal', value: '' },
        ],
    },
    {
        question: 'Quels sont les revenus de votre ménage ? Ces informations nous permettront de vous donner la couleur de votre ménage MaPrimeRénov\'',
        options: [
            { title: '1' },
            { title: '2' },
            { title: '3' },
            { title: '4' },
            { title: '5' },
            { title: '+' },
        ],
        subOptions: revenueOptions,
    },
    {
        question: 'Veuillez fournir vos informations de contact',
        options: [
            { type: 'text', placeholder: 'Civilité', value: '' },
            { type: 'text', placeholder: 'Prénom', value: '' },
            { type: 'text', placeholder: 'Nom', value: '' },
            { type: 'tel', placeholder: 'Téléphone', value: '' },
            { type: 'email', placeholder: 'E-mail', value: '' },
        ],
    },
];

const Questionnaire = () => {
    const [step, setStep] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState(Array(questions.length).fill(null));
    const [addressDetails, setAddressDetails] = useState({ address: '', city: '', postalCode: '' });
    const [surface, setSurface] = useState(''); // État pour stocker la surface du logement
    const [contactDetails, setContactDetails] = useState({ civility: '', firstName: '', lastName: '', phone: '', email: '' });
    const [direction, setDirection] = useState('forward');
    const [showNextButton, setShowNextButton] = useState(false);
    const totalSteps = questions.length;

    useEffect(() => {
        if (selectedOptions[step] === null) {
            setShowNextButton(false);
        }
        console.log(step)
    }, [step, selectedOptions]);

    const nextStep = () => {
        console.log(step)
        setDirection('forward');
        setTimeout(() => {
            if (step < totalSteps - 1) {
                setStep(step + 1);
            }
        }, 0);
    };

    const finalStep = () => {
        setDirection('forward');
        setTimeout(() => {
           
                setStep(step + 1);
            
        }, 0);
    };

    const prevStep = () => {
        setDirection('backward');
        setShowNextButton(true);
        setTimeout(() => {
            if (step > 0) {
                setStep(step - 1);
            }
        }, 0);
    };

    const handleOptionSelect = (optionIndex) => {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[step] = optionIndex;
    
        setSelectedOptions(updatedSelectedOptions);

        setShowNextButton(false);
        nextStep();
    };

    const handleAddressChange = (e, field) => {
        setAddressDetails({ ...addressDetails, [field]: e.target.value });
    };

    const handleAddressSubmit = () => {
        if (addressDetails.address && addressDetails.city && addressDetails.postalCode) {
            nextStep();
        } else {
            alert('Tous les champs sont obligatoires.');
        }
    };

    const handleSurfaceChange = (e) => {
        setSurface(e.target.value);
    };

    const handleSurfaceSubmit = () => {
        if (surface) {
            nextStep();
        } else {
            alert('Veuillez entrer la surface du logement.');
        }
    };

    const handleContactChange = (updatedContactDetails) => {
        setContactDetails(updatedContactDetails);
    console.log(contactDetails)
    };
    

    const handleContactSubmit = () => {
        if (contactDetails.civility && contactDetails.firstName && contactDetails.lastName && contactDetails.phone && contactDetails.email) {
            nextStep();
        } else {
            alert('Tous les champs sont obligatoires.');
        }
    };

    const handleNextButtonClick = () => {
        console.log("test1")
        if (step === 5) {
            handleSurfaceSubmit(); // Appeler la fonction pour soumettre la surface
        } else if (step === 6) {
            handleAddressSubmit();
        } else if (step === 8) {
            handleContactSubmit(); 
        } else {
            // Par défaut, appeler la fonction générique pour passer à l'étape suivante
            if (step < totalSteps - 1 && selectedOptions[step] !== null) {
                setShowNextButton(false);
                nextStep();
            }
        }
    };

    const handleOptionSelectStay = (subOptionIndex) => {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[step + 1] = subOptionIndex;
        setSelectedOptions(updatedSelectedOptions);
    };

    return (
        <div className="questionnaire-container">
            <img src={planete} alt={'Ecologie'} className='img-planete'/>
            <div className="progress-bar" style={{ width: `${((step + 1) / totalSteps) * 100}%` }}></div>
            <div className="question-content">
                <TransitionGroup>
                    <CSSTransition
                        key={step}
                        timeout={500}
                        classNames={direction === 'forward' ? 'slide-forward' : 'slide-backward'}
                    >
                        <div>
                            {step < 5 ? (
                                <Question 
                                    question={questions[step].question}
                                    options={questions[step].options}
                                    onOptionSelect={handleOptionSelect}
                                    selectedOption={selectedOptions[step]}
                                />
                            ) : step === 5 ? (
                                <div className="form-general">
                                    <h2>{questions[step].question}</h2>
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            placeholder="Surface en m²"
                                            value={surface}
                                            onChange={handleSurfaceChange}
                                        />
                                    </div>
                                </div>
                            ) : step === 6 ? (
                                <AddressForm
                                    addressDetails={addressDetails}
                                    handleAddressChange={handleAddressChange}
                                />
                            ) : step === 7 ? (
                                <RevenueForm
                                    question={questions[step].question}
                                    options={questions[step].options}
                                    subOptions={questions[step].subOptions}
                                    initialSelectedOptionIndexes={[selectedOptions[step], selectedOptions[step + 1]]}
                                    handleOptionSelectStay={handleOptionSelectStay}
                                    handleOptionSelect={handleOptionSelect}
                                    handleNextButtonClick={handleNextButtonClick}
                                />
                            ) : step === 8 ? (
                                <ContactForm
                                    contactDetails={contactDetails}
                                    handleContactChange={handleContactChange}
                                    nextStep={finalStep}
                                />
                            ) : (
                                <LoadingScreen />
                            )}
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <div className="controls">
                    {(step > 0 && step < 9) && (
                        <button onClick={prevStep} className="custom-button">
                            <FaChevronLeft size={12} style={{marginRight: 10}}/> Retour
                        </button>
                    )}
                    <button 
                            onClick={handleNextButtonClick} 
                            className={`custombutton ${(showNextButton || step === 5 || step === 6  ) ? 'visible' : ''}`}
                            // disabled={selectedOptions[step] === null}
                        >
                            Suivant <FaChevronRight size={12} style={{marginLeft: 5}}/>
                    </button>
            </div>
            </div>
            
        </div>
    );
};

export default Questionnaire;
