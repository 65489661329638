import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ContactForm = ({ contactDetails, handleContactChange, nextStep }) => {
    const [errors, setErrors] = useState({
        civility: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    });

    const validateForm = () => {
        const { civility, firstName, lastName, phone, email } = contactDetails;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let formValid = true;
        const newErrors = {
            civility: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
        };

        if (!civility) {
            newErrors.civility = 'La civilité est requise.';
            formValid = false;
        }
        if (!firstName) {
            newErrors.firstName = 'Le prénom est requis.';
            formValid = false;
        }
        if (!lastName) {
            newErrors.lastName = 'Le nom est requis.';
            formValid = false;
        }
        if (!phone) {
            newErrors.phone = 'Le numéro de téléphone est requis.';
            formValid = false;
        } else if (!/^[0-9]{10}$/.test(phone)) {
            newErrors.phone = 'Le numéro de téléphone doit comporter 10 chiffres.';
            formValid = false;
        }
        if (!email || !emailRegex.test(email)) {
            newErrors.email = 'Veuillez entrer une adresse email valide.';
            formValid = false;
        }

        setErrors(newErrors);

        if (formValid) {
            nextStep(); // Appel à la fonction nextStep si le formulaire est valide
        }
    };

    const handleCivilityChange = (civility) => {
        handleContactChange({ ...contactDetails, civility });
        setErrors({ ...errors, civility: '' });
    };

    const handleChange = (e, field) => {
        handleContactChange({ ...contactDetails, [field]: e.target.value });
        setErrors({ ...errors, [field]: '' });
    };

    return (
        <div className="form-general">
            <h2>Coordonnées</h2>

            {/* Civilité */}
            <div className="sub-options-container">
                <button
                    className={`sub-option-button ${contactDetails.civility === 'Madame' ? 'selected' : ''} ${
                        errors.civility ? 'error' : ''
                    }`}
                    onClick={() => handleCivilityChange('Madame', 'civility')}
                >
                    Madame
                </button>
                <button
                    className={`sub-option-button ${contactDetails.civility === 'Monsieur' ? 'selected' : ''} ${
                        errors.civility ? 'error' : ''
                    }`}
                    onClick={() => handleCivilityChange('Monsieur', 'civility')}
                >
                    Monsieur
                </button>
            </div>
            {errors.civility && <div className="error-message">{errors.civility}</div>}

            {/* Prénom */}
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Prénom"
                    value={contactDetails.firstName}
                    onChange={(e) => handleChange(e, 'firstName')}
                    className={errors.firstName ? 'error' : ''}
                    required
                />
                <label className={errors.firstName ? 'input-label-error' : 'input-label'}>Prénom</label>
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>

            {/* Nom */}
            <div className="input-group">
                <input
                    type="text"
                    placeholder="Nom"
                    value={contactDetails.lastName}
                    onChange={(e) => handleChange(e, 'lastName')}
                    className={errors.lastName ? 'error' : ''}
                    required
                />
                <label className={errors.lastName ? 'input-label-error' : 'input-label'}>Nom</label>
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>

            {/* Téléphone */}
            <div className="input-group">
                <input
                    type="tel"
                    placeholder="Téléphone"
                    value={contactDetails.phone}
                    onChange={(e) => handleChange(e, 'phone')}
                    className={errors.phone ? 'error' : ''}
                    required
                />
                <label className={errors.phone ? 'input-label-error' : 'input-label'}>Téléphone</label>
                {errors.phone && <div className="error-message">{errors.phone}</div>}
            </div>

            {/* E-mail */}
            <div className="input-group">
                <input
                    placeholder="E-mail"
                    value={contactDetails.email}
                    onChange={(e) => handleChange(e, 'email')}
                    className={errors.email ? 'error' : ''}
                    required 
                />
                <label className={errors.email ? 'input-label-error' : 'input-label'}>Adresse email</label>
                {errors.email && <div className="error-message">{errors.email}</div>}
            </div>

            <button onClick={validateForm} className={`custombuttonvalidate`}>
                Calculer ma Prime
            </button>
        </div>
    );
};



export default ContactForm;
