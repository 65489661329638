// src/components/Question.js
import React from 'react';
import './Question.css';

const Question = ({ question, options, onOptionSelect, selectedOption }) => {
    return (
        <div className="question">
            <h2>{question}</h2>
            <div className="options">
                {options.map((option, index) => (
                    <div 
                        key={index} 
                        className={`option ${selectedOption === index ? 'selected' : ''}`} 
                        onClick={() => onOptionSelect(index)}
                    >
                        {option.image && <img src={option.image} alt={option.title || 'Option image'} />}
                        {option.title && <h3>{option.title}</h3>}
                        {option.text && <p>{option.text}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Question;
