import React, { useEffect, useState } from 'react';

const LoadingScreen = ({ onFinish }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            // onFinish(); // Appeler la fonction onFinish après 5 secondes
        }, 5000); // 5000 ms = 5 secondes

        return () => clearTimeout(timer); // Nettoyer le timer lorsque le composant est démonté
    }, []);

    return (
        <div className={"loading-screen"}>
            {loading ? (
                <div className="loading-container">
                    <div className="loader"></div>
                    <p>Nous simulons votre projet, veuillez patienter ...</p>
                </div>
            ) : (
                <div className="loading-container">
                    <h2>Bonne nouvelle, vous êtes éligible !</h2>
                    <p>Un conseiller vous rappellera pour effectuer une étude détaillée de votre projet.</p>
                </div>
            )}
        </div>
    );
};

export default LoadingScreen;
