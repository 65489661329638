import React, { useState } from 'react';
import './Sidebar.css';
import pompe from '../assets/pompe.jpg'
import tache from '../assets/tache.png'

const carouselData = [
    {
        title: "La pompe à chaleur le top de l'économie d'énergie !",
        description: "Découvrez l'efficacité ultime avec notre pompe à chaleur, qui offre des économies d'énergie exceptionnelles tout en respectant l'environnement. Profitez d'un confort optimal toute l'année grâce à une technologie avancée et fiable. Adoptez la solution écologique pour un futur durable dès aujourd'hui !",
        image: pompe,
    },
    {
        title: 'Titre 2',
        description: 'Description pour le deuxième élément.',
        image: 'image2.jpg',
    },
    {
        title: 'Titre 3',
        description: 'Description pour le troisième élément.',
        image: 'image3.jpg',
    },
];

const Sidebar = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const index = currentIndex === 0 ? carouselData.length - 1 : currentIndex - 1;
        setCurrentIndex(index);
    };

    const nextSlide = () => {
        const index = currentIndex === carouselData.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(index);
    };

    return (
        <aside className="sidebar">
            <div className="carousel-container">
            <img src={tache} alt={"ecologie"} className="carousel-bg-image" />
                {carouselData.map((item, index) => (
                    <div
                        key={index}
                        className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                    >
                        <div className="carousel-content">
                            <h1 className='title'>{item.title}</h1>
                            <p className='description'>{item.description}</p>
                        </div>
                        <div className='img-container'>
                        <img src={item.image} alt={item.title} className="carousel-image" />
                        </div>
                    </div>
                ))}
                <button className="carousel-button left" onClick={prevSlide}>&#10094;</button>
                <button className="carousel-button right" onClick={nextSlide}>&#10095;</button>
            </div>
        </aside>
    );
};

export default Sidebar;
